import React from 'react';
import { BsInstagram, BsWhatsapp } from 'react-icons/bs';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <div className="social-media">
        {/* <div className="social-icons"> */}
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/esquadriasziix">
            <BsInstagram />
          </a>
          <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=5547999250999&text=Ol%C3%A1!%20Seja%20bem-vindo%20a%20Ziix.%20Envie%20sua%20d%C3%BAvida%20e%20logo%20ser%C3%A1%20atendido%20por%20um%20de%20nossos%20consultores.">
            <BsWhatsapp />
          </a>
        {/* </div> */}
      </div>

      <div className="footer-content-container">
        <div className="comercial-info">
          <p>
            Ziix Casa das Esquadrias LTDA
            {/* CNPJ: 46.316.586/0001-07 */}
          </p>
          <p>
            Av. Doutor Reinaldo Schmithausen, 857
            Cordeiros - Itajaí - SC - CEP 88310-002
          </p>
        </div>

        <div className="footer-copyright">
          
        </div>
      </div>
    </footer>
  );
}

export default Footer;
