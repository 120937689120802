import React, { useState } from 'react';
import { FaRegCopy } from "react-icons/fa";
import './Contato.css';

export default function Contato() {
  const [isCopied, setIsCopied] = useState(false);
  const ziixEmail = "comercial@ziix.com.br";


  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  };

  const copy = (text, setIsCopied) => {
    copyTextToClipboard(text);
    setIsCopied(true);
    const oneSec = 1500;
    setTimeout(() => {
      setIsCopied(false);
    }, oneSec);
  };

  return (
    <div id="contato" className="contato-container">
      <p className="contato-descricao">Para dúvidas e orçamentos, envie um email para: </p>

      <button
        className="tooltip button"
        type="button"
        onClick={ () => copy(ziixEmail, setIsCopied) }
      >
        <span className="tooltiptext">
          {isCopied ? 'Copiado!' : 'Copiar'}
        </span>
        <p className="email-btn">{ ziixEmail }</p>
        <FaRegCopy />
      </button>

      <p className="contato-descricao">Ou entre em contato através do 
        <a target="_blank" rel="noreferrer" className="whatsapp" href="https://api.whatsapp.com/send?phone=5547999250999&text=Ol%C3%A1!%20Seja%20bem-vindo%20a%20Ziix.%20Envie%20sua%20d%C3%BAvida%20e%20logo%20ser%C3%A1%20atendido%20por%20um%20de%20nossos%20consultores.">
          Whatsapp
        </a>.
      </p>
    </div>
  )
}
