import React from 'react';

function NotFound() {
  return (
    <div className="notfound-container">
      <p>Not Found</p>
    </div>
  );
}

export default NotFound;
