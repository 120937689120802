import React from 'react';
import './BenefitCard.css';

function BenefitCard(props) {
  const { data: { title, description } } = props;

  return (
    <div className="benefitCard-container">
      <h4>{ title }</h4>
      
      <p>{ description }</p>
    </div>
  );
}

export default BenefitCard;
