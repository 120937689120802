// import logo from './resources/logo.png';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './pages/Home'
import NotFound from './pages/NotFound'

function App() {
  return (
    <>
      <NavBar />

      <Routes>
        <Route index path="/" element={<Home />} />
        <Route index path="/" element={<NotFound />} />
      </Routes>
      
      <Footer />
    </>
  );
}

export default App;
