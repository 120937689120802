import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../resources/logo.png';
import { FaBars, FaTimes } from "react-icons/fa";
import './NavBar.css';

function NavBar() {
  const [click, setClick] = useState(false);
  
  const handleClick = () => setClick(!click);
  
  const closeMobileMenu = () => setClick(false);
  
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/#home">
            <img src={logo} alt="logo Ziix" />
          </Link>

          <div className="menu-icon" onClick={ handleClick }>
            {
              click ? <FaTimes /> : <FaBars />
            }
          </div>

          <ul className={ click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/#home" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>

            <li className="nav-item">
              <Link onClick={closeMobileMenu} to="/#empresa" >Empresa</Link>
            </li>

            <li className="nav-item">
              <Link onClick={closeMobileMenu} to="/#vantagens" >Vantagens</Link>
            </li>

            <li className="nav-item">
            <Link onClick={closeMobileMenu} to="/#esquadrias">Esquadrias</Link>
            </li>

            <li className="nav-item">
              <Link onClick={closeMobileMenu} to="/#contato" >Contato</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default NavBar