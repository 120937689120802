import React from 'react';
import './Banner.css';
import logo from '../resources/ziix.png';

function Banner() {
  return (
    <div id="home" className="banner-container">

      <div className="banner-wrapper">
        <img src={logo} alt="logo da Wine" />

        <h2>
          Esquadrias de Aluminio e PVC
        </h2>
      </div>
    </div>
  );
}

export default Banner;
