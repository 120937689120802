import React from 'react';
import './Vantagens.css';
import vantagens from '../resources/vantagens.json';
import BenefitCard from './cards/BenefitCard';

function Vantagens() {
  return (
    <div id="vantagens" className="vantagens-container">

        <h3>Vantagens</h3>
      <div className="vantagens-wrapper">
        {
          vantagens.map((item, index) => <BenefitCard data={ item } key={ index }/> )
        }
      </div>
    </div>
  );
}

export default Vantagens;
