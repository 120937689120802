import React from 'react';
import './Wolff.css';

function Wolff() {
  return (
    <div className="wolff-container">

      <div className="wolff-wrapper">
        <h2>Padrão Wolff de Esquadrias de PVC</h2>
        
        <p className="wolff-description">Segurança, qualidade, robustez e inovação são alguns dos itens presentes nas esquadrias de PVC. Representamos a Wollf nos sistemas de perfis de PVC, empresa nacional com tecnologia Alemã desenvolvida especialmente para projetos exigentes.</p>
        <p className="modelos-esquadrias">BRANCO – PRETO – GRAFITE – BRONZE – AMADERADO</p>
      </div>
    </div>
  );
}

export default Wolff;
