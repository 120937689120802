import React from 'react';
import './Aluminio.css';

function Aluminio() {
  return (
    <div className="aluminio-container">

      <div className="aluminio-wrapper">
        <h2>Esquadrias Aluminio</h2>
        
        <p className="aluminio-description">Trabalhamos com todas linhas de Esquadrias em Alumínio. Portas de Giro e Correr, Janelas e persianas integrada, basculante, fachadas e coberturas.</p>
        <p className="modelos-esquadrias">LINHA SUPREMA – GOLD – LINHA 42 – FACHADA ESTRUTURAL GLAZING <br />
            COBERTURAS – ALUMÍNIO RIPADO (portas e painéis)</p>
      </div>
    </div>
  );
}

export default Aluminio;
