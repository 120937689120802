import React from 'react';
import './Empresa.css';

function Empresa() {
  return (
    <div id="empresa" className="empresa-container">

      <div className="empresa-wrapper">
        <h2>Sobre nós</h2>
        
        <p>Somos uma nova e jovem empresa, que conta com mais de 20 anos de experiência de seus profissionais no ramo.<br />
          Nossa sede está localizada em Itajaí – SC e atendemos toda a região do entorno do norte catarinense.<br />
          A empresa conta com sede própria em uma área de aproximadamente 1.000 m², máquinas e equipamentos de última geração e profissionais experientes e altamente qualificados.<br />
          Nosso produto tem como finalidade modernizar ambientes, com linhas de esquadrias de alta tecnologia, oferecendo um design inovador ao projeto.<br />
          <br />
          Nosso foco é atender todo tipo de solução de esquadrias de Alumínio e PVC.
        </p>
      </div>
    </div>
  );
}

export default Empresa;
