import React from 'react';
import Banner from '../components/Banner';
import Empresa from '../components/Empresa';
// import Garantia from '../components/Garantia';
// import ModelosHome from '../components/ModelosHome';
import Wolff from '../components/Wolff';
import Vantagens from '../components/Vantagens';
import Aluminio from '../components/Aluminio';
import Contato from '../components/Contato';
import Esquadrias from '../components/EsquadriasCarousel';

function Home() {
  return (
    <>
        <Banner />
        <Empresa />
        <Wolff />
        <Aluminio />
        <Vantagens />
        <Esquadrias />
        <Contato />
        {/* <ModelosHome /> */}
        {/* <Garantia /> */}
    </>
  );
}

export default Home;
