import React from 'react';
import Carousel from 'nuka-carousel';
import './EsquadriasCarousel.css';

import fig1 from '../resources/images/carousel1.png';
import fig2 from '../resources/images/carousel2.png';
import fig3 from '../resources/images/carousel3.png';
import fig4 from '../resources/images/carousel4.png';
import fig5 from '../resources/images/carousel5.png';
import fig6 from '../resources/images/carousel6.png';
import fig7 from '../resources/images/carousel7.png';
import fig8 from '../resources/images/carousel8.png';
import fig9 from '../resources/images/carousel9.png';
import fig10 from '../resources/images/carousel10.png';
import fig12 from '../resources/images/carousel12.png';
import fig13 from '../resources/images/carousel13.png';
import fig14 from '../resources/images/carousel14.png';
import fig15 from '../resources/images/carousel15.png';
import fig16 from '../resources/images/carousel16.png';

function EsquadriasCarousel() {
  return (
    <div id="esquadrias" className="carousel-container">
      <div className="carousel-wrapper">
        <Carousel>
          <div><img src={fig1} alt="" /></div>
          <div><img src={fig2} alt="" /></div>
          <div><img src={fig3} alt="" /></div>
          <div><img src={fig4} alt="" /></div>
          <div><img src={fig5} alt="" /></div>
          <div><img src={fig6} alt="" /></div>
          <div><img src={fig7} alt="" /></div>
          <div><img src={fig8} alt="" /></div>
          <div><img src={fig9} alt="" /></div>
          <div><img src={fig10} alt="" /></div>
          <div><img src={fig12} alt="" /></div>
          <div><img src={fig13} alt="" /></div>
          <div><img src={fig14} alt="" /></div>
          <div><img src={fig15} alt="" /></div>
          <div><img src={fig16} alt="" /></div>
        </Carousel>
      </div>
    </div>
  );
}

export default EsquadriasCarousel;
